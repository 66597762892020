import React from 'react';
import { Helmet } from 'react-helmet';

const TrackingTags: React.FC = () => (
  <>
    <Helmet>
      {/* Google Tag Manager */}
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T2QJV6RC');
        `}
      </script>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T2QJV6RC"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>

      {/* Google tag (gtag.js) - Second Tag */}
      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=AW-540843892"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-540843892');
        `}
      </script>
       */}
       {/* <!-- Google tag (gtag.js) --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-540843892"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-540843892');`}
      </script>

      {/* <!-- Event snippet for New brand Signup Welcome page (app) conversion page --> */}
      <script>
        {`
          if (['/welcome/brand', '/welcome/agency', '/welcome/talentpartner','/welcome/influencer'].includes(window.location.pathname)){
            gtag('event', 'conversion', {'send_to': 'AW-540843892/wZyBCJ22xcEZEPS-8oEC'});
          }
        `}
      </script>

      {/* Meta Pixel Code */}
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '797531127729808');
          fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        {`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=797531127729808&ev=PageView&noscript=1" />
        `}
      </noscript>
    </Helmet>
  </>
);

export default TrackingTags;
